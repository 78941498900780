import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home/Home";
import Service from "./component/Service/Service";
import "./App.css";
import Login from "./component/Login/Login";
import Profile from "./component/Profile/Profile";
import ComingSoon from "./component/Extra/ComingSoon";
import Error from "./component/Error/Error";
import Register from "./component/Register/Register";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/data" element={<Service />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/me" element={<ComingSoon />} />
          <Route path="/nc" element={<ComingSoon />} />
          <Route path="/kc" element={<ComingSoon />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
