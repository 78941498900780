// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* end footer */
.lastFooter{
    display: flex;
    justify-content: space-between;
    background-color: #313B48;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/component/Footer/LastFooter.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["/* end footer */\r\n.lastFooter{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    background-color: #313B48;\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
