import React, { useState, useEffect } from 'react';
import './Profile.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';

const Profile = () => {

  const [data1, setData1] = useState([]); 
  const [image, setImage] = useState([]); 
  const [contactDetails, setContactDetails] = useState([]); 
  const [number, setNumber] = useState([]);
  const [email, setEmail] = useState([]);
  const [categories, setCategories] = useState([]);
  const [websiteLink, setWebsiteLink] = useState([]);
  const [specialSkillsExperience, setSpecialSkillsExperience] = useState([]);
  const [credits, setCredits] = useState([]);
  const [affiliations, setAffiliations] = useState([])



  const { id } = useParams();



  const fetchData = async () => {

    try {
      const apiKey = "XUXwCLYkGdZtPL53";
          const apiUrl = `https://filmdb.klizos.com/api/film_crew/profile/?id=${id}`;       
          const response = await axios.get(apiUrl, {headers: {'x-api-key': apiKey}});       
          if (response.status === 200) {
            const profilesData = response.data.profile;
          if (profilesData) 
          {
            setData1(profilesData);
             setImage(profilesData.image);
             setContactDetails(profilesData.contact_details);
             setEmail(profilesData.email);
             setNumber(profilesData.phone_number);
             setCategories(profilesData.categories);
             setWebsiteLink(profilesData.website_links)
             setSpecialSkillsExperience(profilesData["special_skills_&_experience"]);
             setCredits(profilesData.all_credits)
             setAffiliations(profilesData.affiliations)
            }
          } else { throw new Error('Network response was not ok.');}
        
      } catch (error) {console.error(error);}
    }

  useEffect(() => {
    fetchData();
  }, [id]);





  const dataArray = Object.values(data1);


  console.log("datatatta",data1)



  return (
    <>
      <Navbar />
      <div className='container ' style={{ background: '#253240' }}>
        <div className="content-area pb-5">
          {dataArray.length > 0 ? (
            <>
              <div className='row '>
                <div className='col-12 col-md-6 col-lg-6 col-xl-6' style={{whiteSpace:'nowrap'}} >
                  <h1 className='text-white mb-5'>Film Crew & Support Services Directory</h1>
                </div>
              </div>

              <div className='profiles p-0 mb-4'>
                <div className='row profile g-4 justify-content-start align-items-start'>
                  <div className='col-md-6 col-lg-4 col-xl-3 d-flex flex-row profile-header-data h-auto pt-2' >
                    {/* Profile Image */}
                    <div>
                      <img src={image} alt='' style={{ width: '100%' }} className='profile-image' />
                    </div>

                    {/* Contact Details */}
                    <div className='one-details px-4' >
                      {contactDetails.map((detail, index) => (
                        <div key={index}>
                          {index === 0 ? <p className='one-details-data'>{detail}</p> : <p className='one-details-data'>{detail}</p>}
                        </div>
                      ))}

                      <button className='profile-resume-button fw-bold px-4'>VIEW RESUME</button>
                    </div>
                  </div>

                  <div className='col-md-6 col-lg-4 col-xl-3 profile-header-data' style={{verticalAlign: 'middle', lineHeight: '20px' }}>
                    {/* Contact */}
                    <h3>Contact</h3>
                    <p>                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.9999 15.46L15.7299 14.85L13.2099 17.37C10.3711 15.9262 8.06368 13.6188 6.6199 10.78L9.1499 8.25L8.5399 3H3.0299C2.4499 13.18 10.8199 21.55 20.9999 20.97V15.46Z" fill="#20E28C"/>
  </svg>{number}</p>
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
    <path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L13.06 12.34C12.41 12.75 11.59 12.75 10.94 12.34L4.4 8.25C4.29973 8.19371 4.21192 8.11766 4.14189 8.02645C4.07186 7.93525 4.02106 7.83078 3.99258 7.71937C3.96409 7.60796 3.9585 7.49194 3.97616 7.37831C3.99381 7.26468 4.03434 7.15581 4.09528 7.0583C4.15623 6.96079 4.23632 6.87666 4.33073 6.811C4.42513 6.74533 4.53187 6.69951 4.6445 6.6763C4.75712 6.65309 4.87328 6.65297 4.98595 6.67595C5.09863 6.69893 5.20546 6.74453 5.3 6.81L12 11L18.7 6.81C18.7945 6.74453 18.9014 6.69893 19.014 6.67595C19.1267 6.65297 19.2429 6.65309 19.3555 6.6763C19.4681 6.69951 19.5749 6.74533 19.6693 6.811C19.7637 6.87666 19.8438 6.96079 19.9047 7.0583C19.9657 7.15581 20.0062 7.26468 20.0238 7.37831C20.0415 7.49194 20.0359 7.60796 20.0074 7.71937C19.9789 7.83078 19.9281 7.93525 19.8581 8.02645C19.7881 8.11766 19.7003 8.19371 19.6 8.25Z" fill="#20E28C"/>
  </svg> {email}</p>
                  </div>

                  <div className='col-md-6 col-lg-4 col-xl-3 profile-header-data'>
                    {/* Categories */}
                    <h3>Categories</h3>
                    <div>
                      {categories.map((categoryObj, index) => (
                        <div key={index}>
                          <p style={{ color: '#20E28C' }}>{categoryObj.category}-{categoryObj.sub_category}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className='col-md-6 col-lg-4 col-xl-3 profile-header-data'  style={{verticalAlign: 'middle', lineHeight: '20px' }}>
                    {/* Weblinks */}
                    <h3>Weblinks</h3>
                    {websiteLink && (
                      <div>
                        {websiteLink.map((categoryObj, index) => (
                          <div key={index}>
                            <p style={{whiteSpace:'nowrap'}} className='text-truncate'>{categoryObj}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

            {/* part 2 */}
            <div className='container-fluid' >
              <div className="profile-data h-auto mb-4 mt-4 p-4">
                <div className='row g-4'>
                  {specialSkillsExperience && (
                    <div className='col-12'>
                      <div className='d-flex flex-row align-items-center'>
                      <div class="big-circle " ></div> <h4 className='px-2' style={{ color: '#20E28C', textAlign: 'start' }}>SPECIAL SKILLS & EXPERIENCE</h4>

                      </div>
                      
                      <p className='px-5 colortextprofile' style={{ textAlign: 'start' }}>
                        {specialSkillsExperience.split('.').map((sentence, index) => (
                          <React.Fragment key={index}>
                            {sentence.trim()}
                            {index < specialSkillsExperience.split('.').length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  )}

                  <div className='col-12'>
                  {credits!==null ?
                  <>
                  <div className='d-flex flex-row align-items-center'>
                      <div class="big-circle " ></div> 
                       <h4 className='px-2' style={{ color: '#20E28C', textAlign: 'start' }}>CREDITS</h4>

                      </div>
                  
                    <div className='row justify-content-between credit-data w-100' >
                      {credits.map((c, index) => (
                        <div key={index} className='mb-3 col-lg-4 col-md-6'>
                          <h5 style={{ textAlign: 'start' }} className='colortextprofiles'>
                            {c.category}
                          </h5>
                          {c.credits && typeof c.credits === 'object' ? (<>
                          <p style={{ textAlign: 'start'}} className='colortextprofile col-sm-8'>
                              {Object.values(c.credits).map((value, valueIndex) => (
                                <React.Fragment key={valueIndex}>
                                <li key={index} style={{ listStyle: 'disc', textAlign: 'start', marginBottom: '0px', lineHeight: '1.5',}}>{value}</li> 
                                </React.Fragment>
                              ))}
                            </p>
                            </>
                          ) : (
                            <p>No credit data available.</p>
                          )}
                        </div>
                      ))}
                    </div>
                    </>
                    : ''
                    }
                  </div>

                  <div className='col-12'>
                  {affiliations!==null ?
                  <>
                  <div className='d-flex flex-row align-items-center'>
                      <div class="big-circle " ></div>  <h4 className='px-2' style={{ color: '#20E28C', textAlign: 'start' }}>Affiliations</h4>

                      </div>

                    
                    <div className='d-flex flex-column justify-content-start px-5'>
                      {affiliations.map((a, index) => (
                        <li key={index} style={{ listStyle: 'disc', textAlign: 'start', marginBottom: '-10px' }}>
                          {a}
                        </li>
                      ))}
                    </div>
                    </>
                    :'' }
                  </div>

                  <div className='col-12'>
                  <div className='d-flex flex-row align-items-center'>
                      <div class="big-circle " ></div> <h4 className='px-2' style={{ color: '#20E28C', textAlign: 'start' }}>COLOR CODE KEY</h4>

                      </div>
                    
                    <li style={{ textAlign: 'start', paddingLeft: '1.5rem' }}>Statewide</li>
                  </div>
                </div>
              </div>
            </div>


            {/* part 2 */}



              </div>

            </>
          ) : (
            <div style={{display:'flex',justifyContent:'center',alignItems:'center' ,height:'40vh'}}><InfinitySpin 
            width='200'
            color="#20E28C"
          /></div>
          )}
        </div>   
      </div> 
      <Footer/>

        

      

    </>
  )
}

export default Profile