import React from 'react'
import '../Extra/ComingSoon.css'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
const Error = () => {
  return (
    <>
    <section className='coming_soon py-5' style={{ backgroundImage:`url('/assets/images/errorpage.jpg')`}}>
    <Navbar />
        <div className="container">
            <div className="row align-items-center g-5 g-lg-5 text-center text-md-start">
                <div className="col-lg-3 col-md-4">
                    <img src="/assets/images/error404.jpg" alt="" className="img-fluid w-100 bg_image"  style={{maxWidth: '300px'}}/>
                </div>
                <div className="col-lg-9 col-md-8">
                    <h1 className="csoon_title font-bebas display-1 text-white fw-bold">404 <br /> ERROR</h1>
                    <p className='lead'>Errors illuminate innovation, guiding us through uncharted bytes.</p>
                    <Link to="/home" className='btn btn-outline-primary search-buttons w-auto px-lg-4 py-2 btn-lg'>Back to Home</Link>
                </div>
            </div>
        </div>
    </section>
    {/* <Footer/> */}
    </>
  )
}

export default Error