import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevClick = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextClick = () => {
    onPageChange(currentPage + 1);
  };

  const getPageRange = () => {
    const range = 2; 
    let start = Math.max(1, currentPage - range);
    let end = Math.min(totalPages, currentPage + range);
    
    if (start <= 4) {
      end = Math.min(end + (5 - end + start - 1), totalPages);
    }
    
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  return (
    <nav>
      <ul className="pagination">
        <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
          <button className="page-link" style={{ background: '#20E28C', color: 'black', fontSize: '12px', }} onClick={handlePrevClick} disabled={currentPage === 1}>
            {"<"}
          </button>
        </li>
        {getPageRange().map(pageNumber => (
          <li key={pageNumber} className={currentPage === pageNumber ? 'page-item active' : 'page-item'}>
            <button
              className="page-link"
              onClick={() => onPageChange(pageNumber)}
              style={currentPage === pageNumber ? { color: 'black', background: '#20E28C', fontSize: '12px' } : { color: '#20E28C', fontSize: '12px' }}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        <li className={currentPage === totalPages ? 'page-item disabled' : 'page-item'}>
          <button className="page-link" style={{ background: '#20E28C', color: 'black', fontSize: '12px' }} onClick={handleNextClick} disabled={currentPage === totalPages}>
            {">"}
          </button>
        </li>
        {/* <li className="page-item">
          <button className="page-link" onClick={() => onPageChange(totalPages)} style={{ color: '#20E28C', fontSize: '12px' }}>
            {totalPages}
          </button>
        </li> */}
      </ul>
      <p className="pagination-text text-end pe-2">
        Page {currentPage} out of <span onClick={() => onPageChange(totalPages)} style={{cursor: 'pointer', color: 'var(--accent, #20E28C)', fontWeight: '700',}}>{totalPages}</span>
      </p>
    </nav>
  );
};

export default Pagination;
