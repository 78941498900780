import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${BACKEND_BASE_URL}user/login`, {
        email,
        password,
      });

      if (data && data.token) {
        localStorage.setItem('data', JSON.stringify(data));
        toast.success('Sign in successful', { position: 'top-right', autoClose: 3000 });
        navigate('/home');
      } else {
        toast.error(`${data.message}`, { position: 'top-right', autoClose: 3000 });
        navigate('/');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred', { position: 'top-right', autoClose: 3000 });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  const handleEyeButtonClick = (e) => {
    e.preventDefault(); 
    togglePasswordVisibility();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login_page">
      <form className="form-signin" onSubmit={handleSignIn}>
        <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
        <div className="custom-input">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            value={email}
            onChange={handleEmailChange}
            required
            autoFocus
          />
        </div>
        <div className="custom-input password-input-container">
          <div className="password-input">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button className={`eye-button ${showPassword ? 'visible' : ''}`} onClick={handleEyeButtonClick}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>
        <button className="btn btn-lg btn-primary btn-block" type="submit">
          Sign in
        </button>
        <p className="mb-3 text-muted">- Film Crew DATABASE</p>
        {responseMessage && <p>{responseMessage}</p>}
      </form>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default Login;
