// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  background-color: #253240;
  font-family: 'Open Sans', sans-serif!important;
  font-size: 14px!important;
  background-color: #253240!important;
}

.fw-semi{
  font-weight: 600!important;
}

.font-bebas{
  font-family: "Bebas Neue", sans-serif!important;
}

.content-area {
  padding-top: 100px;
}

.active>.page-link, .page-link.active, .page-link:hover {
  background: rgb(32, 226, 140)!important;
  border-color: rgb(32, 226, 140)!important;
  color: #fff!important;
}

.btn-primary{
  background-color: #20E28C!important;
  border-color: #20E28C!important;
  color: #000;
}
.btn-outline-primary{
  border-color: #20E28C!important;
  color: #20E28C!important;
}
.btn-outline-primary:hover{
  background-color: #20E28C!important;
  color: #000!important;;
}

.page-link {
  background: #313B48!important;
  border-color: #313B48!important;
  border-radius: 4px;
  font-size: 14px!important;
  color: #989DA3!important;
  padding: .35rem .75rem!important;
}

.pagination .page-item{
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,yBAAyB;EACzB,8CAA8C;EAC9C,yBAAyB;EACzB,mCAAmC;AACrC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;EACvC,yCAAyC;EACzC,qBAAqB;AACvB;;AAEA;EACE,mCAAmC;EACnC,+BAA+B;EAC/B,WAAW;AACb;AACA;EACE,+BAA+B;EAC/B,wBAAwB;AAC1B;AACA;EACE,mCAAmC;EACnC,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,kBAAkB;EAClB,yBAAyB;EACzB,wBAAwB;EACxB,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');\nbody{\n  background-color: #253240;\n  font-family: 'Open Sans', sans-serif!important;\n  font-size: 14px!important;\n  background-color: #253240!important;\n}\n\n.fw-semi{\n  font-weight: 600!important;\n}\n\n.font-bebas{\n  font-family: \"Bebas Neue\", sans-serif!important;\n}\n\n.content-area {\n  padding-top: 100px;\n}\n\n.active>.page-link, .page-link.active, .page-link:hover {\n  background: rgb(32, 226, 140)!important;\n  border-color: rgb(32, 226, 140)!important;\n  color: #fff!important;\n}\n\n.btn-primary{\n  background-color: #20E28C!important;\n  border-color: #20E28C!important;\n  color: #000;\n}\n.btn-outline-primary{\n  border-color: #20E28C!important;\n  color: #20E28C!important;\n}\n.btn-outline-primary:hover{\n  background-color: #20E28C!important;\n  color: #000!important;;\n}\n\n.page-link {\n  background: #313B48!important;\n  border-color: #313B48!important;\n  border-radius: 4px;\n  font-size: 14px!important;\n  color: #989DA3!important;\n  padding: .35rem .75rem!important;\n}\n\n.pagination .page-item{\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
