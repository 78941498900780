import React from 'react'
import './ComingSoon.css'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
// import Footer from '../Footer/Footer'
// import bgImage from 'https://res.cloudinary.com/klizo-solutions/image/upload/v1691572592/Film%20Crew/film_crew_bhoxus.jpg'
const ComingSoon = () => {
    return (
        <>
            <section className='coming_soon py-5' style={{ backgroundImage: `url('https://res.cloudinary.com/klizo-solutions/image/upload/v1691572592/Film%20Crew/film_crew_bhoxus.jpg')` }}>
                <Navbar />
                <div className="container">
                    <div className="row align-items-center g-5 g-lg-5 text-center text-md-start">
                        <div className="col-lg-3 col-md-4">
                            <img src="https://res.cloudinary.com/klizo-solutions/image/upload/v1691574192/Film%20Crew/pngwing.com_ralixb.png" alt="" className="img-fluid w-100 bg_image" style={{ maxWidth: '300px' }} />
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <h1 className="csoon_title font-bebas display-1 text-white fw-bold">Coming <br /> Soon</h1>
                            <p className='lead' >Launching soon for Maine, Kentucky, and North Carolina
                            <br /> <br /> 
                                <p >• Discover the Talent: A curated showcase of actors, directors, and filmmakers from your region.</p>
                                <p>• Your Connection to Cinema: A portal into the world of film, crafted with love, soon to be at your doorstep. </p>
                            </p>



                            {/* <button className='btn btn-outline-primary search-buttons fw-semi fw-bold w-auto px-lg-4 py-2 btn-lg'>Back to Home</button> */}
                            <Link to="/home" className='btn btn-outline-primary search-buttons w-auto px-lg-4 py-2 btn-lg'>Back to Home</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer/> */}
        </>
    )
}

export default ComingSoon