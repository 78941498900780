// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming_soon {
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat!important;
    background-color: #333333!important;
    background-blend-mode: multiply;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}
.csoon_title{
    line-height: 1!important;
    letter-spacing: 5px;
}
.coming_soon nav {
    background: transparent;
}

.coming_soon nav ul, .coming_soon nav button, .coming_soon nav .search-bar {
    display: none!important;
}

@media only screen and (max-width: 767px){
    .bg_image{
        max-width: 100px!important;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Extra/ComingSoon.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,sCAAsC;IACtC,mCAAmC;IACnC,+BAA+B;IAC/B,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,wBAAwB;IACxB,mBAAmB;AACvB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".coming_soon {\n    width: 100%;\n    min-height: 100vh;\n    height: auto;\n    background-size: cover;\n    background-repeat: no-repeat!important;\n    background-color: #333333!important;\n    background-blend-mode: multiply;\n    background-position: bottom;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    color: #fff;\n}\n.csoon_title{\n    line-height: 1!important;\n    letter-spacing: 5px;\n}\n.coming_soon nav {\n    background: transparent;\n}\n\n.coming_soon nav ul, .coming_soon nav button, .coming_soon nav .search-bar {\n    display: none!important;\n}\n\n@media only screen and (max-width: 767px){\n    .bg_image{\n        max-width: 100px!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
