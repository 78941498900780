import React, { useState } from "react";
import styled from "styled-components";
import './Navbar.css'
import { useNavigate } from "react-router-dom";



const Nav = styled.nav`
  padding: 0 20px;
  min-height: 9vh;
  background: #1c2022;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  font-size: 25px;
  color: white;
  font-family: 'Bebas Neue', sans-serif;
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;

  li:nth-child(2) {
    margin: 0px 20px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

const Item = styled.li``;

const Link = styled.a`
  color: white;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const NavIcon = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;

  @media (min-width: 992px) {
    display: none;
  }
`;

const Line = styled.span`
  display: block;
  border-radius: 50px;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #fff;
  transition: width 0.4s ease-in-out;

  :nth-child(2) {
    width: ${props => (props.open ? "40%" : "70%")};
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${props => (props.open ? "100vh" : 0)};
  width: 100vw;
  background: #1c2022;
  transition: height 0.4s ease-in-out;
  z-index: 9998; 

  @media (min-width: 992px) {
    display: none;
  }
`;

const OverlayMenu = styled.ul`
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => (props.open ? "100vh" : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: height 0.4s ease-in-out;

  li {
    opacity: ${props => (props.open ? 1 : 0)};
    font-size: 25px;
    margin: 10px 0px;
    transition: opacity 0.4s ease-in-out;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;




const Navbar = () => {
  let navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  }


  const [toggle, toggleNav] = useState(false);
  return (
    <>
      <Nav className="overlay fixed-top" style={{ padding: '0 6rem' }}>
        <div className="container d-flex justify-content-between align-items-center">
          <Logo>FILM CREW <span style={{ color: "#20E28C" }}>DATABASE</span></Logo>
          <Menu className="mx-auto">
            <Item>
              <Link target="#" href="">
                About
              </Link>
            </Item>
            <Item>
              <Link target="#" href="">
                Industry
              </Link>
            </Item>
            <Item>
              <Link target="#" href=""  style={{whiteSpace:'nowrap'}}>
                Programs & Funding
              </Link>
            </Item>
            <Item >
              <Link target="#" href="" style={{ color: "#20E28C",whiteSpace:'nowrap' }}>
                Resource & Partners
              </Link>
            </Item>


          <div className="search-bar d-none d-lg-flex" style={{ background: '#6C7882', maxWidth: '300px' }}>
            <button style={{ background: '#6C7882' }} className="search-bar-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
            <input type="text" className="custom-input" placeholder="Search..." style={{ background: '#6C7882', color: 'black' }} />

          </div>

          <Item >
          <div className="px-2">
            <button style={{ background: '#6C7882',borderRadius:'15px',padding:'0.2rem 1rem' }} onClick={logout}>
              logout
            </button>

          </div>


            </Item>
          </Menu>

          <NavIcon onClick={() => toggleNav(!toggle)}>
            <Line open={toggle} />
            <Line open={toggle} />
            <Line open={toggle} />
          </NavIcon>
        </div>
      </Nav>
      <Overlay open={toggle}>
        <OverlayMenu open={toggle}>
          <Item>
            <Link target="#" href="">
              About
            </Link>
          </Item>
          <Item>
            <Link target="#" href="">
              Industry
            </Link>
          </Item>
          <Item>
            <Link target="#" href="">
              Programs & Funding
            </Link>
          </Item>
          <Item >
            <Link target="#" href="" style={{ color: "#20E28C" }}>
              Resource & Partners
            </Link>
          </Item>

          <Item >
            <div className="search-bar" style={{ background: '#6C7882' }}>
              <button style={{ background: '#6C7882' }} className="search-bar-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button>
              <input type="text" className="custom-input" placeholder="Search..." style={{ background: '#6C7882', color: 'black' }} />

            </div>
          </Item>
          <Item>
          <div >
            <button style={{ background: '#6C7882',borderRadius:'25px',padding:'0.2rem 1.5rem' }} onClick={logout}>
              logout
            </button>

          </div>
          </Item>
        </OverlayMenu>
      </Overlay>
    </>
  );
};

export default Navbar;
