import React from 'react'
import './LastFooter.css'

const LastFooter = () => {
  return (
    <div>
      <div className='lastFooter'> 
        <div>© FILM CREW DATABASE | Design & Developed by Klizos Solution</div>
        <div> Transparancy Online | Accessibility | Privacy Statement | Security Statement</div>
      </div>
    </div>
  )
}

export default LastFooter