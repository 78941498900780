import React from 'react'
import  './Service.css'
import Footer from '../Footer/Footer'
import LastFooter from '../Footer/LastFooter'
import Navbar from '../Navbar/Navbar'

const Service = () => {
  return (
    <>
<Navbar/>
        <div className='container-fluid c' style={{padding:'0 6rem',marginTop:'52rem'}}>
        <div className="row">
        <div className="col-12 col-md-12 col-sm-12 taking-header-space">
        </div>
        </div>
        <div className="row">
            <div style={{display:'flex', justifyContent:'start !important'}} className="col-12 col-md-12 col-sm-12 heading text">Film Crew & SUPPORT SERVICES DIRECTORY</div>
        </div>  
        <div className="row">  
            <div className="col-12  heading-data">
            <div className='row' >
                <div className="intro first col-2 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                    <div className='intro-image'>
                    <img className='intro-image-tag ' src="/assets/images/image2.jpg" alt="" />
                    </div>
                    <div className='intro-data'>
                        <h3 className='intro-data-name'>KERI KASSING</h3>
                        <h5 className='text'>DENVER,CO</h5>
                        <button className='view-resume'>VIEW RESUME</button>
                    </div>
                </div>
                </div>
                    
                    
                <div className='row'>
                <div className="intro seconds col-2 col-md-6 col-sm-12 d-flex justify-content-center">
                    <div className="intro-contact"><h3 className='text'>Conatact</h3></div>
                    <div className="intro-contact" style={{whiteSpace:'nowrap'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#20E28C" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg> 
                    &nbsp;
                    <span className='text'>(745) 345-2345</span>  
                    </div>
                    <div className="intro-contact" style={{whiteSpace:'nowrap'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#20E28C" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                     <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                    </svg>
                    &nbsp;
                    <span className='text'>karikassing@gmail.com</span>
                      
                    </div>
                </div>
                </div>

                <div className='row'>
                <div className="intro third col-2 col-md-6 col-sm-12 d-flex justify-content-center align-items-center"> 
                   <div><h3 className='text'>CATEGORIES</h3></div>
                   <div className='third-data'>
                        <div className='green' style={{whiteSpace:'nowrap'}}>Accounting - Production Accountant</div>
                        <div className='green'style={{whiteSpace:'nowrap'}}>Accounting - Assistant</div>
                        <div className='green'style={{whiteSpace:'nowrap'}}>Production Office - Coordinate / POC</div>
                   </div> 
                </div>

                </div>




                <div className='row'>
                <div className="intro col-2 col-md-6 col-sm-12 d-flex justify-content-center align-items-center ">
                    <div className='text'>WEBLINKS</div>
                    <div className='green'>IMBD</div>
                </div> 

                </div>

                </div>
        </div>

        <div className='row'>
            <div className="col-12 heading-details">
                <ul>
                    <li style={{listStyle:"disc", color:'#20E28C'}}>
                         <span  className='text heading-details-data'>SPECIAL SKILLS & EXPERIENCE</span>
                         <div className='detils'>

                         <div className='colors'>
                            10+ yrs. Experience in NYC  & Denver
                         </div>
                         <div className='colors'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, ipsa.
                         </div>
                         <div className='colors'>Lorem ipsum dolor sit amet.</div>
                         <div className='colors'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam repudiandae, quia molestias vero fugit impedit asperiores quasi quisquam quaerat aut iure ex perferendis laudantium distinctio! Deleniti nisi vel incidunt dicta! ex perferendis laudantium distinctio! Deleniti nisi vel incidunt dicta! ex perferendis laudantium distinctio! Deleniti nisi vel incidunt dicta!</div>
                         <div className='colors'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quis vero natus sequi aspernatur odio consequuntur iure magni! Dicta maxime nisi esse vitae, facilis maiores earum nesciunt. Amet exercitationem temporibus est!</div>
                         </div>

                    </li>
                    <li  style={{listStyle:"disc", color:'#20E28C'}}>
                        <span className='text heading-details-data'>CREDITS</span>
                        <div className='row text heading-details-datas'>
                           <div className='col-md-3 col-sm-12 colors'>
                           <div> Accounting - Assistant</div>
                           <ul>
                            <li>2016</li>
                            <li>2014</li>
                           </ul>
                            </div>
                           <div className='col-md-3 col-sm-12 colors'>
                            <div>Accounting - Production Accountant</div>
                            <ul>
                                <li>2019</li>
                            </ul>
                            </div>
                           <div className='col-md-3 col-sm-12 colors' >
                            <div>Production Office - Coordinate / POC</div>
                            <ul style={{padding:"0px",margin:'0px'}}>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}}>2019</li>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}} >2016</li>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}}>2016</li>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}}>2016</li>
                            </ul>
                           </div>
                        </div>

                    </li>
                    <li style={{listStyle:"disc", color:'#20E28C'}}>
                        <span  className='text heading-details-data'>AFFILATIONS</span>
                        <div className='three'>
                            <ul>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}}>International Alliance of Theritical Stage Employes (IATSE)</li>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}}>Local # / Other:7</li>
                                <li style={{listStyle:"disc", color:'#C1C4C8'}}>Licence : N/A</li>
                            </ul>
                        </div>
                    </li>
                    <li style={{listStyle:"disc", color:'#20E28C'}}>
                        <span  className='text heading-details-data'> COLOR CODE KEY</span>
                        </li>
                        <div className='d-flex '>
                           <div>Statewide </div> <div class="square"></div>
                        </div>
                </ul>


            </div>
        </div>
        </div>
                <Footer/>
        
                <LastFooter/>
                </>
  )
}

export default Service