import React, { useState, useEffect } from 'react'
import './Home.css'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { Link, Navigate } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const Home = () => {

 const  navigate = useNavigate() ;
 let userData = window.localStorage.getItem("data")

 if (userData) {
   userData = JSON.parse(userData); 
 } else {
   navigate('/')
 }

  const [data1, setData1] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [total, setTotal] = useState([])
  const [profilesDatas, setProfilesDatas] = useState(null);
  const [profilesDataForapi, setProfilesDataForapi] = useState(null);
  const [category, setCategory] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [toggleValue, setToggleValue] = useState('AND');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  

  const [region, setRegion] = useState('');
  const [county, setCounty] = useState('');
  const [affiliation, setAffiliation] = useState('');
  const [affiliationOptions, setAffiliationOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [countyOptions, setCountyOptions] = useState([]);
  const [searchTrue, setSearchTrue] = useState(false);


  let subcategorys = [];



  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6C7882' : '#6C7882',
      color: state.isFocused ? '#fff' : '#fff',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#fff' : '#fff',
      fontSize: '14px', 
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#6C7882',
      fontSize: '14px', 
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '100%',
      color: '#fff',
    }),
  };


  const selectStyless = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#6C7882' : '#6C7882',
      color: state.isFocused ? '#fff' : '#fff',
      borderRadius: '4px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#fff' : '#fff', 
      fontSize: '14px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#6C7882', 
      fontSize: '14px', 
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '100%',
      color: '#fff',
    }),
  };


  const fetchData = async () => {

    try {
      const apiKey = 'XUXwCLYkGdZtPL53';
      if (keyword.length > 0) {
        const apiUrl = 'https://filmdb.klizos.com/api/film_crew/keyword_search/';
        const formData = new FormData();
        formData.append('keyword', keyword);
        const response = await axios.post(apiUrl, formData, {
          headers: {
            'x-api-key': apiKey,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          const profilesData = response.data;
          if (profilesData) { setData1(profilesData) }
        } else {
          throw new Error('Network response was not ok.');
        }
      } else if (searchTrue) {

        const apiUrl = 'https://filmdb.klizos.com/api/film_crew/search_by_filters/';
        const response = await axios.post(
          apiUrl,
          {
            categories: selectedCategories,
            sub_categories: selectedSubcategory,
            match_type: toggleValue,
          },
          {
            headers: {
              'x-api-key': apiKey,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status === 200) {
          const profilesData = response.data;
          if (profilesData) { setData1(profilesData) }
        } else { throw new Error('Network response was not ok.'); }

      } else {
        const apiUrl = `https://filmdb.klizos.com/api/film_crew/dashboard/?page=${currentPage}`;
        const response = await axios.get(apiUrl, { headers: { 'x-api-key': apiKey } });
        if (response.status === 200) {
          const profilesData = response.data;
          setProfilesDatas(profilesData)
          setTotal(profilesData.total_no_pages);
          if (profilesData) { setData1(profilesData) }
        } else { throw new Error('Network response was not ok.'); }
      }
    } catch (error) { console.error(error); }
  }


  const fetchDatas = async () => {
    try {
      const apiKey = 'XUXwCLYkGdZtPL53';
      const apiUrl = 'https://filmdb.klizos.com/api/film_crew/filters/';
      const response = await axios.get(apiUrl, { headers: { 'x-api-key': apiKey } });
      if (response.status === 200) {
        const profilesData = response.data;
        setProfilesDatas(profilesData)
        setProfilesDataForapi(profilesData)
        setTotal(profilesData.total_no_pages);
        if (profilesData) {
          setRegion(profilesData.data.regions); setCounty(profilesData.data.counties); setAffiliation(profilesData.data.affiliations);
          const affiliationOptionsData = profilesData.data.affiliations.map((value) => ({ value, label: value }));
          setAffiliationOptions(affiliationOptionsData);
          const categoryData = profilesData.data.categories;
          const categoryDataOptions = Object.entries(categoryData).map(([value, data]) => {
            if (typeof data === 'object' && data.category && data.sub_categories) {
              return { value: data.category,label: data.category, };} return null; }).filter(Boolean);
              setCategory(categoryDataOptions)

          const regionOptionsData = profilesData.data.regions;
          const optionssss = Object.entries(regionOptionsData).map(([label, value]) => ({ value: Object.keys(value)[0], label: Object.keys(value)[0], }));
          setRegionOptions(optionssss);
          const countyOptionData = profilesData.data.counties;
          const countyopt = Object.entries(countyOptionData).map(([label, value]) => ({ value: value, label: value, }));
          setCountyOptions(countyopt);
        }
      } else { throw new Error('Network response was not ok.'); }

    } catch (error) { console.error(error); }
  }

  useEffect(() => {

    fetchDatas()
  }, [selectedRegion])



  useEffect(() => {
    if (profilesDatas && profilesDatas.profiles) {
      const categories = Object.keys(profilesDatas.profiles);
      setOptions(categories);
    }
  }, [profilesDatas]);



const handleCategoryChange = (selectedOptions) => {
  const selectedValues = selectedOptions.map(option => option.value);
  setSelectedCategories(selectedValues);
  const subcategoryOptions = [];

  selectedValues.forEach(selectedValue => {
    const selectedCategory = profilesDataForapi.data.categories.find(category => category.category === selectedValue);

    if (selectedCategory) {
      subcategoryOptions.push(...selectedCategory.sub_categories.map(subcategory => ({
        value: subcategory,
        label: subcategory,
      })));
    }
  });

  console.log("Subcategory Options:", subcategoryOptions);
  setSubcategories(subcategoryOptions)
};

  console.log("231",subcategories)
  const handleSubcategoryChange = (selectedOption) => {
    const selectedValues = selectedOption.map(option => option.value);
    setSelectedSubcategory(selectedValues);
    subcategorys.push(selectedOption.value)
  };

  const handleChangeRegion = (selectedOption) => {
    setSelectedRegion(selectedOption.value);
  };

  const handleChangeCounty = (selectedOption) => {
    setSelectedRegion(selectedOption.value);
  };

  const handleChangeAffiliation = (selectedOptions) => {
    if (selectedOptions) {
      if (Array.isArray(selectedOptions)) {
        const selectedValues = selectedOptions.map((option) => option.value);
        setAffiliation(selectedOptions);

      } else {
        const selectedValues = [selectedOptions.value];
        setAffiliation([selectedOptions]);
      }
    } else {
      setAffiliation([]);
    }
  };






  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };



  const [isToggled, setIsToggled] = useState(false);
  const [currentValue, setCurrentValue] = useState('AND');

  const handleToggle = () => {
    setIsToggled(prevValue => !prevValue);
    setCurrentValue(prevValue => (prevValue === 'AND' ? 'OR' : 'AND'));
  };



  useEffect(() => {
    if (searchTrue) {
      fetchData();
      setSearchTrue(false);
    }
  }, [searchTrue]);


  const handleSearch = async (event) => {
    event.preventDefault();
    setSearchTrue(true);
  };



  useEffect(() => {
    fetchData();
  }, [keyword, searchCategory, currentPage,]);
  const dataArray = Object.values(data1);
  

const [loader,setLoader]= useState(false);
  const handlePageChange = (pageNumber) => {
    setLoader(true)
    setCurrentPage(pageNumber);
    setTimeout(() => {
      setLoader(false)
    }, 1000);

  };

  const selectOptions = options.map(option => ({ value: option, label: option }));



  return (
    <>

      <div className='home'>


        <div className="container" id='home-tops'>
          <Navbar />
          <div className="content-area pb-5">

            <div className="row mt-4 g-3" >
              <div className="col-12 col-md-12 col-sm-12">
                <h1 className='text-white text-start'>Film Crew & Support Services Directory</h1>
              </div>
            </div>
            <div className="row g-3" style={{ padding: '15px 0px' }}>
              <div className="col-md-4 col-lg-3  col-sm-12" >
                <div className='search-title '>Search Filter</div>
                <div className='search-field-1' >
                  <span style={{color:'white',paddingTop:'3px',paddingBottom:'7px'}} className='d-flex justify-content-start category-key mt-1'>Category</span>
                  <Select
                    value={selectedCategories.map(value => ({ value, label: value }))}
                    onChange={handleCategoryChange}
                    options={category}
                    placeholder='Search By Category...'
                    className='search-fields'
                    styles={selectStyles}
                    isMulti
                  />
                </div>
              
                  {selectedCategories.length > 0 && (
                    <div className='search-field-1' >
                    <Select
                      value={selectedSubcategory.map(value => ({ value, label: value }))}
                      onChange={handleSubcategoryChange}
                      options={subcategories}
                      placeholder='Search By Subcategory...'
                      className='search-fields'
                      styles={selectStyles}
                      isMulti
                    />
                    </div>
                  )}
              



                <hr />

                <div className='location'>Location</div>
                <div  className='search-field-1' >
                  <Select
                    options={regionOptions}
                    defaultValue={regionOptions.filter((option) => region.includes(option.value))}
                    onChange={handleChangeRegion}
                    placeholder="Region"
                    styles={selectStyless}
                    className='search-fields'
                  />


                </div>

                <div  className='search-field-1'>
                  <Select
                    options={countyOptions}
                    defaultValue={countyOptions.filter((option) => region.includes(option.value))}
                    onChange={handleChangeCounty}
                    placeholder="County"
                    styles={selectStyless}
                    className='search-fields'
                  />
                </div>
                <div  className='search-field-1'>
                  <Select
                    options={affiliationOptions}
                    defaultValue={affiliationOptions.filter((option) => affiliation.includes(option.value))}
                    onChange={handleChangeAffiliation}
                    placeholder="Affiliations"
                    styles={selectStyless}
                    className='search-fields'
                  />
                </div>

                <hr />

                <div  className='search-field-1'>
                  <textarea
                    placeholder={keyword ? 'Type something...' : 'Keyword'}
                    value={keyword}
                    onChange={(event) => setKeyword(event.target.value)}
                    rows="4"
                    cols="47"
                    className='textarea '
                  />
                </div>
                <div className='search-tips'>
                  <div className='header'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="#20e28c;">
                      <path d="M2.92218 0L2.06732 0.898808L3.33929 2.23743L4.19293 1.33862L2.92157 0H2.92218ZM14.0784 0L12.8065 1.33862L13.6607 2.23743L14.9321 0.899447L14.0784 0ZM8.5 0.398902C8.29964 0.401459 8.09686 0.413605 7.89286 0.439815C7.88679 0.439815 7.88071 0.438537 7.87464 0.439815C5.41207 0.737074 3.45221 2.83195 3.11161 5.4133C2.83961 7.49092 3.65318 9.37484 5.00893 10.5882C5.5635 11.0865 5.93861 11.7703 6.07143 12.5251V16.3607H7.45571C7.667 16.7424 8.05254 17 8.5 17C8.94746 17 9.333 16.7424 9.54429 16.3607H10.9286V13.8037H10.9856V13.0442C10.9856 12.1071 11.4483 11.1622 12.1999 10.4277C13.2054 9.3678 13.9643 7.86233 13.9643 6.13312C13.9643 2.97514 11.4956 0.369496 8.5 0.398902ZM8.5 1.67743C10.8466 1.64291 12.75 3.66555 12.75 6.13312C12.75 7.47174 12.1611 8.65055 11.3457 9.50844L11.3651 9.5289C10.5548 10.3156 10.0308 11.3756 9.88368 12.5258H7.22804C7.09446 11.4301 6.64821 10.3695 5.80489 9.60881C4.73207 8.64991 4.09275 7.20517 4.30646 5.57376C4.57179 3.56007 6.12364 1.94081 8.02521 1.71899C8.18221 1.69594 8.34032 1.68228 8.49879 1.67807L8.5 1.67743ZM0 6.13312V7.41165H1.82143V6.13312H0ZM15.1786 6.13312V7.41165H17V6.13312H15.1786ZM3.33929 11.3073L2.06793 12.6453L2.92218 13.5448L4.19232 12.2061L3.33929 11.3073ZM13.6607 11.3073L12.8071 12.2061L14.0778 13.5448L14.9321 12.6453L13.6607 11.3073ZM7.28571 13.8043H9.71429V15.0828H7.28571V13.8043Z" fill="#20E28C" />
                    </svg>
                  </div>
                  <div className='search-tips-name' style={{ paddingLeft: '8px', fontSize: '13px', fontFamily: 'Open Sans, sans-serif' }}>Search Tips</div>
                </div>

                <div >
                  <div className="switch-button mb-4">
                    <h5 className="label left-label">{" "}</h5>
                    <label className="switch" htmlFor="checkbox">
                      <input type="checkbox" id="checkbox" checked={isToggled} onChange={handleToggle} />
                      <div className="slider round">
                      </div>
                    </label>
                    <p className="label right-label py-0 ps-3 pe-0">{currentValue === "AND" ? "Broad Search" : "Strict Search"}</p>
                  </div>
                </div>
                <div>
                  <button className='search-buttons' onClick={handleSearch}>Search</button>
                </div>

              </div>

              <div className="col-md-8 col-lg-9 col-sm-12 mt-5 mt-md-0" >
                <div className='crew-listing mt-3' >
                  CREW LISTING
                </div>
                <div className='crew-listing-data'>
                  
                  {loader === false && dataArray.length > 0 ? (
                    <ul className='crew-listing-datatable' >
                      <span style={{ color: 'white' }} className='w-100 d-block' >
                        <div className='custom-scrollbar right_data_area' >
                          {Object.keys(data1.profiles).map((category, index) => (
                            <React.Fragment key={index}>
                              <li style={{ listStyle: "none", textAlign: 'start', marginLeft: '20px' }} className='crew-listing-dataable-li datils'>
                                <div className='d-flex align-items-center' style={{ paddingBottom: '12px' }}>
                                  <div class="big-circle" ></div> <div style={{ padding: "0 10px", color: '#fff' }} className='table-text-data-category'>{category}</div>
                                </div>

                                <div className='crew-listing-datas table-responsive custom-scrollbar' style={{ overflowY: 'hidden',}}>
                                  <table className='crew-listing-table px-4'>

                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                      {Object.keys(data1.profiles[category]).map((subcategory, subIndex) => (
                                        <tr key={subIndex} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between', width: '120%' }}>
                                          <td style={{ color: "#20E28C", }} className='table-text-data-subcategory' >{subcategory}</td>


                                          <tr style={{ textAlign: 'start', color: 'rgba(240,240,240,0.75)', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <th style={{ flex: 1, flexBasis: 0, textAlign: 'start' }}>Name</th>
                                            <th style={{ flex: 1, flexBasis: 0, textAlign: 'start' }}>City/Town</th>
                                            <th style={{ flex: 1, flexBasis: 0, textAlign: 'start' }}>Area</th>
                                            <th style={{ flex: 1, flexBasis: 0, textAlign: 'start' }}>Action</th>

                                          </tr>


                                          {Object.entries(data1.profiles[category][subcategory]).map(([key, value]) => (
                                            <tr key={key} className='datils' style={{ textAlign: 'start', fontFamily: 'Open Sans, sans-serif', paddingBottom: '1.3rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                              <td style={{ flex: 1, flexBasis: 0, textAlign: 'start', }} className='table-text-data' ><Link className="link-no-underline" to={`/profile/${value.colorado_id}`}>{value.title}</Link></td>
                                              <td style={{ flex: 1, flexBasis: 0, textAlign: 'start',  }} className='table-text-data'>{value.city}</td>
                                              <td style={{ flex: 1, flexBasis: 0, textAlign: 'start',  }} className='table-text-data'>{value.region}</td>
                                              <td style={{ flex: 1, flexBasis: 0, textAlign: 'start',  }} className='crew-listing-table-icons table-text-data' >
                                                <div style={{ display: 'flex', justifyContent: 'start' }} >
                                                  {value.colorado_id &&
                                                    <Link className="link-no-underlines" to={`/profile/${value.colorado_id}`} style={{ padding: '0 0.6rem' }}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" fill="currentColor" viewBox="0 0 18 18" >
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 4C5 2.93913 5.42143 1.92172 6.17157 1.17157C6.92172 0.421427 7.93913 0 9 0C10.0609 0 11.0783 0.421427 11.8284 1.17157C12.5786 1.92172 13 2.93913 13 4C13 5.06087 12.5786 6.07828 11.8284 6.82843C11.0783 7.57857 10.0609 8 9 8C7.93913 8 6.92172 7.57857 6.17157 6.82843C5.42143 6.07828 5 5.06087 5 4ZM5 10C3.67392 10 2.40215 10.5268 1.46447 11.4645C0.526784 12.4021 0 13.6739 0 15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15C18 13.6739 17.4732 12.4021 16.5355 11.4645C15.5979 10.5268 14.3261 10 13 10H5Z" fill="#20E28C" />
                                                      </svg>

                                                    </Link>
                                                  }
                                                  {value.pdf_link &&
                                                    <Link className="link-no-underlines" to={`${value.pdf_link}`} target="_blank" style={{ padding: '0 0.6rem' }}>


                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" fill="currentColor" viewBox="0 0 18 21">
                                                        <path d="M3.46159 11.077H2.76929V9.69238H3.46159C3.64521 9.69238 3.8213 9.76532 3.95113 9.89516C4.08096 10.025 4.1539 10.2011 4.1539 10.3847C4.1539 10.5683 4.08096 10.7444 3.95113 10.8742C3.8213 11.0041 3.64521 11.077 3.46159 11.077ZM8.30775 13.8462V9.69238H9.00006C9.18367 9.69238 9.35976 9.76532 9.48959 9.89516C9.61942 10.025 9.69236 10.2011 9.69236 10.3847V13.1539C9.69236 13.3375 9.61942 13.5136 9.48959 13.6435C9.35976 13.7733 9.18367 13.8462 9.00006 13.8462H8.30775Z" fill="#20E28C" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.07692C0 1.52609 0.218818 0.997815 0.608317 0.608317C0.997815 0.218818 1.52609 0 2.07692 0L13.4405 0L18 4.55954V18.6923C18 19.2431 17.7812 19.7714 17.3917 20.1609C17.0022 20.5504 16.4739 20.7692 15.9231 20.7692H2.07692C1.52609 20.7692 0.997815 20.5504 0.608317 20.1609C0.218818 19.7714 0 19.2431 0 18.6923V2.07692ZM3.46154 8.30769H1.38462V15.2308H2.76923V12.4615H3.46154C4.01237 12.4615 4.54065 12.2427 4.93015 11.8532C5.31964 11.4637 5.53846 10.9354 5.53846 10.3846C5.53846 9.83378 5.31964 9.30551 4.93015 8.91601C4.54065 8.52651 4.01237 8.30769 3.46154 8.30769ZM9 8.30769H6.92308V15.2308H9C9.55083 15.2308 10.0791 15.012 10.4686 14.6225C10.8581 14.233 11.0769 13.7047 11.0769 13.1538V10.3846C11.0769 9.83378 10.8581 9.30551 10.4686 8.91601C10.0791 8.52651 9.55083 8.30769 9 8.30769ZM12.4615 15.2308V8.30769H16.6154V9.69231H13.8462V11.0769H15.2308V12.4615H13.8462V15.2308H12.4615Z" fill="#20E28C" />
                                                      </svg>
                                                    </Link>

                                                  }

                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tr>
                                      ))}
                                    </div>
                                  </table>
                                </div>
                              </li>
                            </React.Fragment>
                          ))}




                        </div>
                        <h1 style={{color:'red'}}> {loader}</h1>

                        {/* pagination */}
                        <div style={{ display: 'flex', justifyContent: 'end', margin: '0 0 0 1.5rem', paddingRight: '2rem' }} className='py-3'>
                          <Pagination currentPage={currentPage} totalPages={total} onPageChange={handlePageChange} />

                        </div>


                        {/* pagination */}

                      </span>

                    </ul>
                  ) : ( 
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}> <InfinitySpin
                      width='200'
                      color="#20E28C"
                    /></div>
                  )}

                </div>

              </div>

            </div>
          </div>            
        </div>


        <Footer />


      </div>

    </>

  )
}




export default Home