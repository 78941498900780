import React, { useState } from 'react';
import './Register.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Register = () => {
    let navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [tc, setTc] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${BACKEND_BASE_URL}user/register`, {
        name,
        email,
        password,
        password_confirmation:cpassword,
        tc,

      });

      if (data.status === "success") {
        toast.success('Sign in successful', { position: 'top-right', autoClose: 2000 });
        setTimeout(() => {
            navigate('/');
        }, 3000);

      } else {
        toast.error(`${data.message}`, { position: 'top-right', autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred', { position: 'top-right', autoClose: 3000 });
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value.trim());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  const handleCPasswordChange = (e) => {
    setCPassword(e.target.value.trim());
  };

  const handleEyeButtonClick = (e) => {
    e.preventDefault();
    togglePasswordVisibility();
  };

  const handleEyeButtonClicks = (e) => {
    e.preventDefault();
    toggleCPasswordVisibility(); 
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleCPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };

  const handleTcChange = (e) => {
    setTc(e.target.checked);
  };

  return (
    <div className="login_page">
      <form className="form-signin" onSubmit={handleSignIn}>
        <h1 className="h3 mb-3 font-weight-normal">Please Register</h1>

        <div className="custom-input">
          <input
            type="Name"
            className="form-control"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            required
            autoFocus
          />
        </div>

        <div className="custom-input">
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            value={email}
            onChange={handleEmailChange}
            required
            autoFocus
          />
        </div>
        <div className="custom-input password-input-container">
          <div className="password-input">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <button className={`eye-button ${showPassword ? 'visible' : ''}`} onClick={handleEyeButtonClick}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>


        <div className="custom-input password-input-container">
          <div className="password-input">
            <input
              type={showCPassword ? 'text' : 'password'}
              className="form-control"
              placeholder="Confirm Password"
              value={cpassword}
              onChange={handleCPasswordChange}
              required
            />
            <button className={`eye-button ${showCPassword ? 'visible' : ''}`} onClick={handleEyeButtonClicks}>
              <FontAwesomeIcon icon={showCPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>

        <div className="custom-input">
          <label>
            <input
              type="checkbox"
              checked={tc}
              onChange={handleTcChange}
            />
            I agree to the terms and conditions
          </label>
        </div>
        

        <button className="btn btn-lg btn-primary btn-block" type="submit">
         Register
        </button>
        <p className="mb-3 text-muted">- Film Crew DATABASE</p>
        {responseMessage && <p>{responseMessage}</p>}
      </form>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default Register;
